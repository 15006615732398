const App = {
    run: function(inner, block) {


		const currentBlock = block.querySelector('.elmntl-content-slider')

		if (currentBlock) {
			if (currentBlock.classList.contains('is-admin-preview')) {
				return
			}
		}

        // recommend to use inner element as selector for block to run js
        const sliderContainer = inner.querySelector('.slider')

		const slider = new tns({
			container: sliderContainer,
			items: 1,
			slideBy: 1,
			controls: false,
			prevButton: inner.querySelector('.arrow-left'),
			nextButton: inner.querySelector('.arrow-right'),
			loop: true,
			nav: true,
			navPosition: 'bottom',
			autoplay: true,
            autoplayButton: false,
            autoplayButtonOutput: false,
			responsive: {
				600: {
					controls: true,
				}
			}
		})
    },
    initializeBlock: function(block) {
        let wrapper = block.querySelector('.content-slider__inner-container')
        this.run(wrapper, block)
    },
    initializeBlockEditor: function(block) {
        // gutenberg fire the block
        App.initializeBlock(block[0])
    },
}

window.addEventListener('DOMContentLoaded', () => {
	const blocks = document.querySelectorAll('.elmntl-content-slider')

	blocks.forEach(block => {
		App.initializeBlock(block)
	})
})

if (window.acf) {
    window.acf.addAction(
        'render_block_preview/type=content-slider',
        App.initializeBlockEditor
    )
}